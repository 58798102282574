<template>
  <div
    v-if="scoreDetails !== null && scoreDetails.matchState != 0"
    class="match-real-time-score-board__container"
  >
    <div class="match-real-time-score-board__title-row">
      <div>{{ $t("TEAM") }}</div>
      <div v-for="(session, i) in matchSessionList" :key="i">{{ session }}</div>
      <div
        v-show="
          scoreDetails.homeOtTotalScore != null ||
          scoreDetails.awayOtTotalScore != null
        "
      >
        OT
      </div>
      <div>{{ $t("TOTAL") }}</div>
    </div>
    <div class="match-real-time-score-board__content-row">
      <div>
        <div class="icon-league">
          <img
            :src="`${s3ImgUrl}/${scoreDetails.homeLogo}`"
            class="img-contain w-100 h-100"
          />
        </div>
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 1 }">
        {{ scoreDetails.homeQ1Score }}
      </div>
      <div :class="{ 'highlight-match-session': [2,50].includes(scoreDetails.matchState) }">
        {{ scoreDetails.homeQ2Score }}
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 3 }">
        {{ scoreDetails.homeQ3Score }}
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 4 }">
        {{ scoreDetails.homeQ4Score }}
      </div>
      <div
        v-show="scoreDetails.homeOtTotalScore != null"
        :class="{
          'highlight-match-session':
            scoreDetails.matchState == 5 ||
            scoreDetails.matchState == 6 ||
            scoreDetails.matchState == 7,
        }"
      >
        {{ scoreDetails.homeOtTotalScore }}
      </div>
      <div
        :class="{
          'highlight-match-session':
            scoreDetails.homeTeamScore == scoreDetails.awayTeamScore ||
            parseInt(scoreDetails.homeTeamScore) > parseInt(scoreDetails.awayTeamScore),
        }"
      >
        <strong>{{ scoreDetails.homeTeamScore }}</strong>
      </div>
    </div>
    <div class="match-real-time-score-board__content-row">
      <div>
        <div class="icon-league">
          <img
            :src="`${s3ImgUrl}/${scoreDetails.awayLogo}`"
            class="img-contain w-100 h-100"
          />
        </div>
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 1 }">
        {{ scoreDetails.awayQ1Score }}
      </div>
      <div :class="{ 'highlight-match-session': [2,50].includes(scoreDetails.matchState) }">
        {{ scoreDetails.awayQ2Score }}
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 3 }">
        {{ scoreDetails.awayQ3Score }}
      </div>
      <div :class="{ 'highlight-match-session': scoreDetails.matchState == 4 }">
        {{ scoreDetails.awayQ4Score }}
      </div>
      <div
        v-show="scoreDetails.awayOtTotalScore != null"
        :class="{
          'highlight-match-session':
            scoreDetails.matchState == 5 ||
            scoreDetails.matchState == 6 ||
            scoreDetails.matchState == 7,
        }"
      >
        {{ scoreDetails.awayOtTotalScore }}
      </div>
      <div
        :class="{
          'highlight-match-session':
            scoreDetails.homeTeamScore == scoreDetails.awayTeamScore ||
            parseInt(scoreDetails.homeTeamScore) < parseInt(scoreDetails.awayTeamScore),
        }"
      >
        <strong>{{ scoreDetails.awayTeamScore }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/js/config.js";

export default {
  watch: {
    matchDetailScore: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.updateMatchScoreAndState(newVal);
        }
      },
    },
  },
  beforeUnmount() {
    this.scoreDetails = null;
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["matchDetailScore"]),
  },
  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      matchSessionList: ["Q1", "Q2", "Q3", "Q4"],
      scoreDetails: null,
    };
  },
  methods: {
    init() {
      this.updateMatchScoreAndState(this.matchDetailScore);
    },
    updateMatchScoreAndState(matchDetailScores) {
      this.scoreDetails = matchDetailScores;
      // if empty then "-"
      // if total draw - highlight both
      // highlight leading team score
      let matchDetails = this.scoreDetails;
      if (
        (matchDetails.homeQ1Score == "0" && matchDetails.awayQ1Score == "0") ||
        (matchDetails.homeQ1Score == null && matchDetails.awayQ1Score == null)
      ) {
        matchDetails.homeQ1Score = "-";
        matchDetails.awayQ1Score = "-";
      } else if (
        (matchDetails.homeQ2Score == "0" && matchDetails.awayQ2Score == "0") ||
        (matchDetails.homeQ2Score == null && matchDetails.awayQ2Score == null)
      ) {
        matchDetails.homeQ2Score = "-";
        matchDetails.awayQ2Score = "-";
      } else if (
        (matchDetails.homeQ3Score == "0" && matchDetails.awayQ3Score == "0") ||
        (matchDetails.homeQ3Score == null && matchDetails.awayQ3Score == null)
      ) {
        matchDetails.homeQ3Score = "-";
        matchDetails.awayQ3Score = "-";
      } else if (
        (matchDetails.homeQ4Score == "0" && matchDetails.awayQ4Score == "0") ||
        (matchDetails.homeQ4Score == null && matchDetails.awayQ4Score == null)
      ) {
        matchDetails.homeQ4Score = "-";
        matchDetails.awayQ4Score = "-";
      } else if (
        matchDetails.homeOtTotalScore == "0" &&
        matchDetails.awayOtTotalScore == "0"
      ) {
        matchDetails.homeOtTotalScore = "-";
        matchDetails.awayOtTotalScore = "-";
      }
      if (
        (matchDetails.homeTeamScore == "0" &&
          matchDetails.awayTeamScore == "0") ||
        (matchDetails.homeTeamScore == null &&
          matchDetails.awayTeamScore == null)
      ) {
        matchDetails.homeTeamScore = "-";
        matchDetails.awayTeamScore = "-";
      }
    },
  },
};
</script>

<style scoped>
.match-real-time-score-board__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.688rem;
  color: var(--color-theme-2);
  margin-bottom: 1rem;
}
.match-real-time-score-board__title-row {
  background: var(--color-theme);
  display: flex;
  justify-content: center;
}
.match-real-time-score-board__title-row > div {
  font-weight: 400;
  padding: 0.875rem 1rem;
  flex: 1 0 9.688rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.match-real-time-score-board__content-row {
  display: flex;
  justify-content: center;
  text-align: center;
}
.match-real-time-score-board__content-row > div {
  padding: 0.5rem 1rem;
  flex: 1 0 9.688rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.match-real-time-score-board__title-row div:first-child,
.match-real-time-score-board__content-row div:first-child {
  flex: 1 0 12.5rem;
}
.highlight-match-session {
  color: var(--bg-selected);
}
@media (max-width: 768px) {
  .match-real-time-score-board__title-row div:first-child,
  .match-real-time-score-board__content-row div:first-child {
    flex: 1 0 5rem;
  }
  .match-real-time-score-board__title-row > div,
  .match-real-time-score-board__content-row > div {
    padding: 0.25rem 0.5rem;
    flex: 1 0 2rem;
  }
}
</style>
