<template>
   <div>
        <div class="match-detail__info-body">
            <MatchScoreBoard></MatchScoreBoard>
            <!--  && !isSkeletonLoading.event -->
            <div v-if="isShowEvent">
                <div class="match-detail__info-header" v-show="matchDetailEventList.length>0">{{$t('PLAY_BY_PLAY')}}</div>
                <div v-for="(o, index) in matchDetailEventList" :key="index" >
                    <div class="match-detail__accordion-title" @click="toggleContentTab(index)">
                        <div>{{ o.quarter }} <span v-show="o.quarterHomeScore != null && o.quarterAwayScore != null">{{ o.quarterHomeScore }} - {{ o.quarterAwayScore }}</span></div>
                        <svg class="accordion-arrow" :class="{'accordion-collapse': o.selected}" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20799_29972)">
                                <path d="M6.675 7.15918L10.5 10.9758L14.325 7.15918L15.5 8.33418L10.5 13.3342L5.5 8.33418L6.675 7.15918Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_20799_29972">
                                <rect width="20" height="20" fill="white" transform="translate(0.5 0.000732422)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div v-for="(event, index1) in o.quarterList" :key="index1" v-show="o.selected">
                        <!-- displayType
                        1 - Home
                        2 - Away
                        3 - Neutral -->
                        <div class="match-detail__info-timeline match-detail__info-timeline-game-state 3" v-if="event.displayType == 3">
                            <div>{{ event.content }}</div>
                        </div>
                        <div class="match-detail__info-timeline 1"  v-if="event.displayType == 1 && event.eventTypeKey !='HT_LABEL' && event.eventTypeKey !='FT_LABEL' && event.eventTypeKey !='PEN_LABEL' && event.eventTypeKey != 'AET_LABEL'">
                        <!-- <div class="match-detail__info-timeline 1"  v-if="event.isHome && event.eventTypeKey !='HT_LABEL' && event.eventTypeKey !='FT_LABEL' && event.eventTypeKey !='PEN_LABEL' && event.eventTypeKey != 'AET_LABEL'"> -->
                            <div class="match-detail__info-timeline__left">
                                <div class="display-flex-fe">
                                    <div class="match-detail__info-timeline__left-name" :class="{'with-video-thumbnail': event.videoUrl != null && event.eventType === 'PTS'}">
                                        <div class="match-detail__info-timeline__left-name__1">{{event.content}}</div>
                                        <!-- <div class="match-detail__info-timeline__left-name__1">{{event.playerName}}</div>
                                        <div class="match-detail__info-timeline__left-name__2">{{event.assistName}}</div> -->
                                    </div>
                                    <div class="match__event-type" v-show="event.videoUrl == null || event.videoUrl == ''">
                                        <GameDetailIcon :eventType="event.eventType"></GameDetailIcon>
                                    </div>
                                </div>
                                <div v-if ="event.videoUrl == null || event.videoUrl == '' "></div>
                                <div class="match-detail__thumbnail-section-container display-flex-fe" v-else>
                                    <div class="match-detail__thumbnail-section match-detail__info-replay mr-05rem" style="padding:0;"  @click="handleVideo(event)">
                                        <div class="match-detail__thumbnail-section__overlay flex-col">
                                            <img src="../../../../../static/images/thumbnail_basketball_video_preview.png">
                                            <!-- <video class="match-detail__thumbnail-iframe" controls v-if="event.isAudioType" :src="`${event.videoUrl}#t=0.1`"></video>
                                            <iframe class="match-detail__thumbnail-iframe" v-else  :src="event.videoUrl"  ></iframe>                                  -->
                                        </div>
                                    </div>
                                    <GameDetailIcon :eventType="event.eventType" v-show="!event.videoUrl == null || !event.videoUrl == ''"></GameDetailIcon>
                                </div>
                            </div>
                            <div class="match-detail__info-timeline__middle">
                                    <div v-if="event.eventTypeKey == 'penaltyPenMiss' || event.eventTypeKey == 'penaltyGoal' ">                              
                                    </div>
                                    <div v-else>
                                        <div v-if="event.extraMin == null" class="match-detail__info-timeline__middle-time">{{event.remainTime}}'</div>
                                        <div v-else class="match-detail__info-timeline__middle-time">{{event.remainTime}}<span> + {{event.extraMin}}'</span></div>
                                    </div>
                                <div class="match-detail__info-timeline__middle-score"  v-show="event.eventType === 'PTS'">   
                                    <div class="match-detail__info-timeline__score">
                                        <span>{{ event.homeScore }}</span>
                                        <span class="match-detail__info-timeline__score-divider">-</span>
                                        <span>{{ event.awayScore }}</span>
                                    </div>
                                    <!-- <div>{{event.homeScore}}</div>
                                    <div>-</div>
                                    <div>{{event.awayScore}}</div> -->
                                </div>
                            </div>
                            <div class="match-detail__info-timeline__right"></div>
                        </div>
                        <div class="match-detail__info-timeline 2"  v-if="event.displayType == 2 && event.eventTypeKey !='HT_LABEL' && event.eventTypeKey !='FT_LABEL' && event.eventTypeKey !='PEN_LABEL' &&  event.eventTypeKey !='AET_LABEL' ">
                        <!-- <div class="match-detail__info-timeline 2"  v-if="!event.isHome && event.eventTypeKey !='HT_LABEL' && event.eventTypeKey !='FT_LABEL' && event.eventTypeKey !='PEN_LABEL' &&  event.eventTypeKey !='AET_LABEL' "> -->
                            <div class="match-detail__info-timeline__left">                      
                            </div>
                            <div class="match-detail__info-timeline__middle" >
                                    <div v-if="event.eventTypeKey =='penaltyPenMiss' || event.eventTypeKey =='penaltyGoal' ">                               
                                    </div>
                                    <div v-else>
                                        <div v-if="event.extraMin == null" class="match-detail__info-timeline__middle-time">{{event.remainTime}}'</div>
                                        <div v-else class="match-detail__info-timeline__middle-time">{{event.remainTime}}<span> + {{event.extraMin}}'</span></div>
                                    </div>

                                <div class="match-detail__info-timeline__middle-score" v-show = "event.eventType === 'PTS'">   
                                    <div class="match-detail__info-timeline__score">
                                        <span>{{ event.homeScore }}</span>
                                        <span class="match-detail__info-timeline__score-divider">-</span>
                                        <span>{{ event.awayScore }}</span>    
                                    </div>
                                    <!-- <div>{{event.homeScore}}</div>
                                    <div>-</div>
                                    <div>{{event.awayScore}}</div> -->
                                </div>                   
                            </div>
                            <div class="match-detail__info-timeline__right">
                                <div class="display-flex-fs">
                                    <div class="match__event-type" v-show="event.videoUrl == null || event.videoUrl == ''">
                                        <GameDetailIcon :eventType="event.eventType"></GameDetailIcon>
                                    </div>
                                    <div class="match-detail__info-timeline__right-name" :class="{'with-video-thumbnail': event.videoUrl != null && event.eventType === 'PTS'}">
                                        <div class="match-detail__info-timeline__left-name__1">{{event.content}}</div>
                                        <!-- <div class="match-detail__info-timeline__left-name__1">{{event.playerName}}</div>
                                        <div class="match-detail__info-timeline__left-name__2">{{event.assistName}}</div> -->
                                    </div>                           
                                </div>
                                <div v-if ="event.videoUrl ==null || event.videoUrl == '' "></div>
                                <div class="match-detail__thumbnail-section-container" v-else>
                                    <GameDetailIcon :eventType="event.eventType" v-show="!event.videoUrl == null || !event.videoUrl == ''"></GameDetailIcon>
                                    <div class="match-detail__thumbnail-section match-detail__info-replay ml-05rem" style="padding:0" @click="handleVideo(event)">
                                        <div class="match-detail__thumbnail-section__overlay flex-col" @click="handleVideo(event)">
                                            <img src="../../../../../static/images/thumbnail_basketball_video_preview.png">
                                            <!-- <video class="match-detail__thumbnail-iframe" controls v-if="event.isAudioType" :src="`${event.videoUrl}#t=0.1`" ></video>
                                            <iframe class="match-detail__thumbnail-iframe" v-else  :src="event.videoUrl"  ></iframe> -->
                                        </div>
                                        <!-- <img src="../../../../../static/images/match_detail_pre_videevent.png"> -->
                                        <div class="match-detail__thumbnail-section__overlay flex-col">
                                            <!-- <div class="match-detail__thumbnail-section__play-icon"></div>
                                            <div class="match-detail__thumbnail-section__play-desc">Goal Replay</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="match-detail__info-timeline 3 bg-theme" v-if="event.eventTypeKey === 'HT_LABEL' || event.eventTypeKey === 'FT_LABEL' || event.eventTypeKey === 'PEN_LABEL' || event.eventTypeKey === 'AET_LABEL' ">

                            <div class="match-detail__info-timeline__left display-flex-fe"> </div>
                            <div class="match-detail__info-timeline__middle ">  
                                <div class="match-detail__info-timeline__middle-time" v-if="event.eventTypeKey==='HT_LABEL'">{{$t('HALF_TIME')}}</div>
                                <div class="match-detail__info-timeline__middle-time" v-if="event.eventTypeKey==='FT_LABEL'">{{$t('FULL_TIME')}}</div>
                                <div class="match-detail__info-timeline__middle-time" v-if="event.eventTypeKey==='PEN_LABEL'">{{$t('PEN_SHOOT_OFF')}}</div>
                                <div class="match-detail__info-timeline__middle-time" v-if="event.eventTypeKey==='AET_LABEL'">{{$t('EXTRA_TIME')}}</div>
                                    <div class="match-detail__info-timeline__middle-score">   
                                        <div class="match-detail__info-timeline__score">{{ event.score }}</div>
                                        <!-- <div>{{event.homeScore}}</div>
                                        <div>-</div>
                                        <div>{{event.awayScore}}</div> -->
                                    </div>
                            </div>
                            <div class="match-detail__info-timeline__right"></div>
                        </div>               
                    </div>
                </div>
            </div>
            <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.event"></GeneralContentSkeleton> -->
            <!--  && !isSkeletonLoading.info -->
            <div v-if="matchDetailInfo.matchDate !== null && matchDetailInfo.matchDate !==''">
                <div class="match-detail__info-footer">{{$t("MATCH_INFO")}}</div>
                <div class="match-detail__info-footer__card" v-if="matchDetailInfo.matchDate !== null && matchDetailInfo.matchDate !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("DATE")}}</div>
                    <div class="match-detail__info-footer__card-content">
                        <span>{{ dataFitlerMatchDate(matchDetailInfo.matchDate)}}</span>
                        <!-- <span v-if="currentLocale === 'vn'">{{$t(matchDetailInfo.matchDateDay)}}{{matchDetailInfo.vnMatchDate}}</span>
                        <span v-if="currentLocale === 'th'">{{matchDetailInfo.thMatchDate}}</span>                    
                        <span v-if="currentLocale === 'en'">{{matchDetailInfo.matchDate}}</span> -->
                    </div>
                </div>
                <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.referee !== null && matchDetailInfo.referee !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("REFEREE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.referee}}</div>
                </div>
                <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.stadium !== null && matchDetailInfo.stadium !== '' ">
                    <div class="match-detail__info-footer__card-title">{{$t("VENUE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.stadium}}</div>
                </div>
                 <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.leagueName !== null && matchDetailInfo.leagueName !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("LEAGUE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.leagueName}}</div>
                </div>
            </div>
            <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.info"></GeneralContentSkeleton> -->
        </div>  
        <PopUpVideo ref="popUpVideoId" :currentVideoUrl="currentVideoUrl"></PopUpVideo>             
    </div>
       
</template>

<script>
import PopUpVideo from '@/components/matchDetail/PopUpVideo.vue'
import MatchScoreBoard from '@/components/matchDetail/BasketballMatchScoreBoard.vue';
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
import GameDetailIcon from '@/components/ui/BasketballMatchDetailIcon.vue'
import {mapGetters,mapMutations,mapActions} from 'vuex'
import config from '@/js/config.js'

export default {
    components : {
        PopUpVideo,
        GeneralContentSkeleton,
        GameDetailIcon,
        MatchScoreBoard,
    },
    beforeUnmount() {
        this.matchDetailEventList = [];
    },
    watch: {
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              this.getMatchDetailInfoData();
            }
        },
        manualRefreshMatchDetail: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getMatchDetailInfoData();
                    this.getMatchDetailEventData();
                }
            }
        },
    },
    data() {
        return {
            isShowEvent: false,
             matchTimelineList:[
            {
                position:1,
                isShowVideo:true,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:2,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:3,
                isShowVideo:true,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:1,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
                {
                position:3,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },

            ],
            matchDetailInfo:{
                matchDateDay: ''
            },
            matchDetailEventList:[],
            userViewingEventList: {
                bool: false,
                selectedIndex: [],
            },
            matchId:'',
            eventInterval: null,
            currentVideoUrl :'',
            isSkeletonLoading: {
                info: false,
                event: false,
            },
            cnLocaleList: config.cnLocaleList,
        }
    },
    beforeUnmount() {
        clearInterval(this.eventInterval)
    },
    mounted() {
        this.getMatchDetailInfoData();
        this.getMatchDetailEventDataIntervel();
    },
    created() {
         this.matchId = this.$route.params.matchId
    },
    computed: {
        ...mapGetters([
            'isStopTimmer',
            'currentLocale',
            'manualRefreshMatchDetail',
            'matchDetailScore',
        ]),
    },

    methods: {
        ...mapMutations([
            "MANUAL_REFRESH_MATCH_DETAIL",
        ]),
        ...mapActions([
            "getBasketballMatchDetaiInfo",
            "getBasketballMatchDetailEvent"
        ]),
        async getMatchDetailInfoData(){
            let params = {
                matchId: this.matchId,
                timeZone: this.$tools.getCurrentTimeZone()
            }

            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.info = true;
            }
            const result = await this.getBasketballMatchDetaiInfo(params)
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.info = false;
                this.verifyIfAllAPICallsDone();
            }
            
            let matchDate = new Date(result.result.matchDate);
            let FormatedMatchDate = `, ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm ${matchDate.getFullYear()}`
            this.matchDetailInfo = result.result;
            // this.matchDetailInfo.vnMatchDate = FormatedMatchDate;
            // this.matchDetailInfo.thMatchDate = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, ${matchDate.getDate()} ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))} ${matchDate.getFullYear()}`;
            // this.matchDetailInfo.matchDate = result.result.matchDate;

            //this.matchDetailInfo.matchDateDay = this.$tools.getFullDay(matchDate.getDay());
        }, 
        dataFitlerMatchDate(data) {
            let result;
            if (data) { 
                let matchDate = new Date(data);

                result = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, 
                          ${matchDate.getDate()} 
                          ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))} 
                          ${matchDate.getFullYear()}
                         `;

                if (this.currentLocale === 'vn') {
                    result = `${this.$i18n.t(this.$tools.getFullDay(matchDate.getDay()))}, ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm ${matchDate.getFullYear()}`;
                } else if (this.cnLocaleList.includes(this.currentLocale)) {
                    result = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, ${matchDate.getDate()}日 ${matchDate.getMonth() + 1}月 ${matchDate.getFullYear()}年`;
                }

                return result;  
            } else {
                return ''
            }

        },

        async getMatchDetailEventDataIntervel(){
            this.getMatchDetailEventData();

            this.eventInterval = setInterval(() => {
                if (this.isStopTimmer) {
                    clearInterval(this.eventInterval);
                } else {
                    this.getMatchDetailEventData();
                }            
            }, 10000);        
        },

        async getMatchDetailEventData(){
            let params ={
                matchId : this.matchId
            }

            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.event = true;
            }
            const result = await this.getBasketballMatchDetailEvent(params);
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.event = false;
                this.verifyIfAllAPICallsDone();
            }

            if (result.result === null) { 
                 if (this.isStopTimmer) {
                      clearInterval(this.eventInterval);
                 }
                this.isShowEvent = false;
            } else {
                this.isShowEvent = true; 
                this.matchDetailEventList = result.result;

                for (let i=0; i<this.matchDetailEventList.length; i++) {
                    if (!this.userViewingEventList.bool)
                        this.matchDetailEventList[i].selected = i === 0 ? true : false;
                    else if (this.userViewingEventList.bool)
                        this.matchDetailEventList[i].selected = this.userViewingEventList.selectedIndex.includes(i) ? true : false;

                    for (let j = 0, m = this.matchDetailEventList[i].quarterList.length; j < m; j++) {
                        if (/\.(mp3|mp4)$/i.test(this.matchDetailEventList[i].quarterList[j].videoUrl)) {
                            this.matchDetailEventList[i].quarterList[j].isAudioType = true;
                        } else {
                            this.matchDetailEventList[i].quarterList[j].isAudioType = false; 
                        }               
                    }
                }
            }
        },
        handleVideo(event){            
            this.currentVideoUrl = event.videoUrl;
            //this.currentVideoUrl = "https://www.youtube.com/embed/iMkhbiO7s9c"; //test video
            this.$refs.popUpVideoId.openCurrentVideo();
        },
        toggleContentTab(index) {
            let selectedArr = this.userViewingEventList.selectedIndex;
            if (!this.userViewingEventList.bool)
                this.userViewingEventList.selectedIndex.push(0);
            this.userViewingEventList.bool = true;

            if (selectedArr.includes(index))
                selectedArr = [...selectedArr.splice(selectedArr.indexOf(index), 1)];
            else
                selectedArr.push(index);

            for (let i = 0, n = this.matchDetailEventList.length; i < n; i++) {
                if (i === index) {
                    this.matchDetailEventList[i].selected = !this.matchDetailEventList[i].selected;
                }
            }
        },
        verifyIfAllAPICallsDone() {
            if (!this.isSkeletonLoading.info && !this.isSkeletonLoading.event) {
                this.MANUAL_REFRESH_MATCH_DETAIL();
            }
        },
    },
}
</script>

<style scoped>
.match-detail__info-header{
    padding: 0 1rem 1rem 1rem; /*0 1rem 1.82rem 1rem*/
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
.match-detail__info-timeline-game-state {
    color: var(--color-theme-2);
    background: var(--color-theme);
}
.match-detail__info-timeline{
    display:flex;
    justify-content: center;
    align-items: center;     
    padding:.75rem .8rem .75rem .8rem;
    border-bottom: 1px solid #212B30;
    font-size: .7rem;
}
.match-detail__info-timeline__left{
    flex:1.5
}
.match-detail__info-timeline__left-name{
    text-align: right;
    margin-right: .3rem;
}
.match-detail__info-timeline__left-name__1{
    font-size: .7rem;
}
.match-detail__info-timeline__left-name__2{
    font-size: .7rem;
    color: var(--color-theme-2);
}
.match-detail__info-timeline__left-name.with-video-thumbnail {
    margin-right: 1.75rem;
}
.match-detail__thumbnail-section-container {
    display: flex;
    align-items: center;
}
.match-detail__info-replay{
    margin-top: .3rem;
    height: 8rem;
    cursor:pointer;
}
.match-detail__info-timeline__yellow{
    width: .5rem;
    height: .8rem;
    border-radius: .1rem;
    background-color: #FFE600;
    
}
.match-detail__info-timeline__yellow.left{
    margin-left: .4rem;
}
.match-detail__info-timeline__yellow.right{
    margin-right: .4rem;
}
.match-detail__info-replay{
    /* padding-top: 50%; */
}
.match-detail__info-timeline__middle{
    flex:.6;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.match-detail__info-timeline__middle-time{
    color: var(--color-theme-2);
    margin-bottom: .3rem;
    width: 5rem;/*6rem*/
}
.match-detail__info-timeline__middle-score{
    display: flex;
}
.match-detail__info-timeline__middle-score>div{
    margin: 0 .3rem;
}
.match-detail__info-timeline__right{
    flex:1.5
}
.match-detail__info-timeline__right-name{
    margin-left: .3rem;
    /* margin-bottom: .3rem; */
}
.match-detail__info-timeline__right-name.with-video-thumbnail {
    margin-left: 1.75rem;
}
.match-detail__info-footer{
    padding: 1.82rem 1rem 1.82rem 1rem;
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
.match-detail__info-footer__card{
    padding: 1rem 1rem .8rem 0;
    margin-left: 1rem;
    border-bottom: .1rem solid #212B30;
}
.match-detail__info-footer__card-title{
    font-size:.7rem;
    color: var(--color-grey-light);
    margin-bottom: .1rem;
}
.match-detail__info-footer__card-content{
    font-size:.88rem
}
.match-detail__thumbnail-iframe{
    height: 100%;
    padding:0;
    width: 100%;
    border:none;
}
.match-detail__accordion-title {
    color: var(--color-theme-2);
    font-size: 0.688rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.625rem 0.5rem;
    border-bottom: 1px solid var(--color-theme);
}
.match-detail__accordion-title svg {
    margin-left: 0.5rem;
}
.match-detail__info-timeline__score {
    font-weight: 700;
    font-size: 0.688rem;
}
.match-detail__info-timeline__score-divider {
    margin: 0 0.5rem;
}
.accordion-arrow {
    transition: all 0.2s;
}
.accordion-collapse {
    transform: rotate(180deg);
}
.match-detail__info-replay {
    width: 8rem;
    height: 4.5rem;
}
.match-detail__info-timeline__name-no-margin {
    margin: 0 !important;
}

@media (max-width: 768px){
    .match-detail__info-replay{
        height: 5rem;
    }
}
@media (min-width: 1000px) {
  .match-detail__info-timeline__middle {
    flex: 0 !important;/*5.6 !important*/
  }
}
</style>